var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'AutoawardList',
            }}},[_vm._v("自動派獎設定")]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'AutoawardList',
            }}},[_vm._v("自動派獎列表")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" "+_vm._s(_vm.isEdit ? " 編輯" : "新增")+"自動派獎 ")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row mb-2 align-items-end"},[_c('div',{staticClass:"col-8 d-flex items-center"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.isEdit ? "編輯" : "新增")+"自動派獎 ")])])]),_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('section',{staticClass:"row mb-8"},[_c('div',{staticClass:"col-12 col-xl-6"},[_c('b-form-group',{attrs:{"label-cols":"12","label-cols-lg":"2","label-size":"sm","label":"標題","label-for":"title"}},[_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":"title","type":"text","state":_vm.v$.form.title.$error ? false : null,"readonly":_vm.isView},model:{value:(_vm.state.form.title),callback:function ($$v) {_vm.$set(_vm.state.form, "title", $$v)},expression:"state.form.title"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.v$.form.title.$error}},[_vm._v("此欄位為必填")])],1),_c('b-form-group',[_c('b-form-row',[_c('b-col',{staticClass:"col-md-6 col-sm-12"},[_c('b-form-group',{attrs:{"label-cols":"12","label-cols-lg":"4","label-size":"sm","label":"活動起日","label-for":"start_at"}},[_c('datepicker',{staticStyle:{"max-width":"10rem"},attrs:{"placeholder":"Select Date","input-class":{
                      'form-control': true,
                      'is-invalid': _vm.v$.form.start_at_date.$error,
                    },"language":_vm.zh,"format":"yyyy-MM-dd","bootstrap-styling":"","disabled":_vm.isView || _vm.isStarted,"disabled-dates":_vm.disabledDates},model:{value:(_vm.state.form.start_at_date),callback:function ($$v) {_vm.$set(_vm.state.form, "start_at_date", $$v)},expression:"state.form.start_at_date"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.v$.form.start_at_date.$error}},[_vm._v("活動起日為必填")])],1)],1),_c('b-col',{staticClass:"col-md-6 col-sm-12"},[_c('b-form-group',{attrs:{"label-cols":"12","label-cols-lg":"4","label-size":"sm","label":"活動迄日","label-for":"end_at"}},[_c('datepicker',{staticStyle:{"max-width":"10rem"},attrs:{"placeholder":"Select Date","input-class":{
                      'form-control': true,
                      'is-invalid': _vm.v$.form.end_at_date.$error,
                    },"format":"yyyy-MM-dd","language":_vm.zh,"bootstrap-styling":"","disabled":_vm.isView,"disabled-dates":_vm.disabledEndDates},model:{value:(_vm.state.form.end_at_date),callback:function ($$v) {_vm.$set(_vm.state.form, "end_at_date", $$v)},expression:"state.form.end_at_date"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.v$.form.end_at_date.$error}},[_vm._v("活動迄日為必填")])],1)],1)],1)],1),_c('b-form-group',{attrs:{"label-cols":"12","label-size":"md","label":"贈獎條件"}},[_c('b-form-radio-group',{staticClass:"pt-3",attrs:{"id":"requirementOption","stacked":""},model:{value:(_vm.state.requirementOption.type),callback:function ($$v) {_vm.$set(_vm.state.requirementOption, "type", $$v)},expression:"state.requirementOption.type"}},[_c('b-form-radio',{attrs:{"value":"immediate","disabled":_vm.isView}},[_vm._v("下單後立即符合資格")]),_c('b-form-radio',{attrs:{"value":"order_amount","disabled":_vm.isView}},[_vm._v("下單滿 "),_c('b-form-input',{staticClass:"m-2",staticStyle:{"width":"6rem"},attrs:{"state":_vm.v$.requirementOption.amount.$error ||
                    _vm.v$.requirementOption.amount.$invalid
                      ? false
                      : null,"disabled":_vm.state.requirementOption.type != 'order_amount',"readonly":_vm.isView,"max":"999999","type":"number","maxLength":"6"},model:{value:(_vm.state.requirementOption.amount),callback:function ($$v) {_vm.$set(_vm.state.requirementOption, "amount", _vm._n($$v))},expression:"state.requirementOption.amount"}}),_vm._v(" 元符合資格 "),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.v$.requirementOption.amount.$error ||
                    !_vm.v$.requirementOption.amount.$invalid}},[_vm._v(" 只能輸入1-999999 ")])],1)],1)],1),_c('b-form-group',{attrs:{"label-cols":"12","label-size":"md","label":"通知 & 贈獎時間"}},[_c('b-form-radio-group',{staticClass:"pt-3",model:{value:(_vm.state.notificationOption.after),callback:function ($$v) {_vm.$set(_vm.state.notificationOption, "after", $$v)},expression:"state.notificationOption.after"}},[_c('b-form-radio',{attrs:{"value":"created_at","disabled":_vm.isView}},[_vm._v("顧客下單後")]),_c('b-form-radio',{attrs:{"value":"pickup_at","disabled":_vm.isView}},[_vm._v("顧客取貨後")])],1),_c('hr'),_c('b-form-radio-group',{staticClass:"pt-3",attrs:{"stacked":""},model:{value:(_vm.state.notificationOption.after_day_type),callback:function ($$v) {_vm.$set(_vm.state.notificationOption, "after_day_type", $$v)},expression:"state.notificationOption.after_day_type"}},[_c('b-form-radio',{attrs:{"value":"immediate","disabled":_vm.isView || _vm.notificationAfterDayImmediateDisabled}},[_vm._v("立即通知並贈獎")]),_c('b-form-radio',{attrs:{"value":"pickup","disabled":_vm.isView || _vm.notificationAfterDayPickupDisabled}},[_c('b-form-input',{staticClass:"m-2",staticStyle:{"width":"5rem"},attrs:{"state":_vm.state.notificationOption.after_day_type == 'pickup'
                      ? _vm.v$.notificationOption.after_days.$error ||
                        _vm.v$.notificationOption.after_days.$invalid
                        ? false
                        : null
                      : null,"disabled":_vm.state.notificationOption.after_day_type != 'pickup',"readonly":_vm.isView,"type":"number","value":_vm.state.notificationOption.after_day_type == 'pickup'
                      ? _vm.state.notificationOption.after_days
                      : 0},on:{"input":function($event){_vm.state.notificationOption.after_days = parseInt($event)}}}),_vm._v(" 日發通知並贈獎 ")],1),_c('b-form-radio',{attrs:{"value":"pickup_award","disabled":_vm.isView || _vm.notificationAfterDayPickupAwardDisabled}},[_c('b-form-input',{staticClass:"m-2",staticStyle:{"width":"5rem"},attrs:{"state":_vm.state.notificationOption.after_day_type == 'pickup_award'
                      ? _vm.v$.notificationOption.after_days.$error ||
                        _vm.v$.notificationOption.after_days.$invalid
                        ? false
                        : null
                      : null,"disabled":_vm.state.notificationOption.after_day_type != 'pickup_award',"readonly":_vm.isView,"value":_vm.state.notificationOption.after_day_type == 'pickup_award'
                      ? _vm.state.notificationOption.after_days
                      : 0,"type":"number","min":"1"},on:{"input":function (e) {
                      _vm.state.awardOption.after_days =
                        _vm.state.awardOption.after_days -
                        _vm.state.notificationOption.after_days +
                        parseInt(e);
                      _vm.state.notificationOption.after_days = parseInt(e);
                    }}}),_vm._v(" 日發通知，通知後 "),_c('b-form-input',{staticClass:"m-2",staticStyle:{"width":"5rem"},attrs:{"state":_vm.state.notificationOption.after_day_type == 'pickup_award'
                      ? _vm.v$.awardOption.after_days.$error ||
                        _vm.v$.awardOption.after_days.$invalid
                        ? false
                        : null
                      : null,"disabled":_vm.state.notificationOption.after_day_type != 'pickup_award',"readonly":_vm.isView,"value":_vm.state.notificationOption.after_day_type == 'pickup_award'
                      ? _vm.state.awardOption.after_days -
                        _vm.state.notificationOption.after_days
                      : 0,"type":"number","min":"1"},on:{"input":function($event){_vm.state.awardOption.after_days =
                      parseInt($event) + _vm.state.notificationOption.after_days}}}),_vm._v(" 日贈獎 ")],1)],1)],1),_c('b-form-group',{attrs:{"label-cols":"12","label-size":"md","label":"贈獎內容"}},[_c('b-form-radio-group',{staticClass:"pt-3",attrs:{"stacked":""},model:{value:(_vm.state.awardOption.type),callback:function ($$v) {_vm.$set(_vm.state.awardOption, "type", $$v)},expression:"state.awardOption.type"}},[_c('b-form-radio',{attrs:{"value":"url","disabled":_vm.isView || _vm.awardUrlDisabled}},[_vm._v(" 發漸強導流連結 "),_c('b-form-input',{staticClass:"m-2",staticStyle:{"width":"300px"},attrs:{"state":_vm.v$.notificationOption.url.$error ||
                    _vm.v$.notificationOption.url.$invalid
                      ? false
                      : null,"disabled":_vm.state.awardOption.type != 'url',"readonly":_vm.isView,"placeholder":"請輸入網址"},model:{value:(_vm.state.notificationOption.url),callback:function ($$v) {_vm.$set(_vm.state.notificationOption, "url", $$v)},expression:"state.notificationOption.url"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.v$.notificationOption.url.$error ||
                    !_vm.v$.notificationOption.url.$invalid}},[_vm._v("導流連結為必填，且為網址格式")])],1),_c('b-form-radio',{attrs:{"value":"cresclab_template","disabled":_vm.isView || _vm.awardTemplateDisabled}},[_vm._v(" 發漸強後台訊息範本 ID "),_c('b-form-input',{staticClass:"m-2",staticStyle:{"width":"7rem"},attrs:{"state":_vm.state.awardOption.type == 'cresclab_template' &&
                    (_vm.v$.awardOption.cresclab_template_id.$error ||
                      _vm.v$.awardOption.cresclab_template_id.$invalid)
                      ? false
                      : null,"disabled":_vm.state.awardOption.type != 'cresclab_template',"readonly":_vm.isView,"type":"number"},model:{value:(_vm.state.awardOption.cresclab_template_id),callback:function ($$v) {_vm.$set(_vm.state.awardOption, "cresclab_template_id", $$v)},expression:"state.awardOption.cresclab_template_id"}}),_vm._v(" 訊息 "),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.v$.awardOption.cresclab_template_id.$error}},[_vm._v("ID 為必填")])],1),_c('b-form-radio',{attrs:{"value":"point","disabled":_vm.isView || _vm.awardPointDisabled}},[_vm._v(" 發 POYA 點數 "),_c('b-form-input',{staticClass:"m-2",staticStyle:{"width":"7rem"},attrs:{"state":_vm.state.awardOption.type == 'point' &&
                    (_vm.v$.awardOption.point.$error ||
                      _vm.v$.awardOption.point.$invalid)
                      ? false
                      : null,"disabled":_vm.state.awardOption.type != 'point',"readonly":_vm.isView,"type":"number"},model:{value:(_vm.state.awardOption.point),callback:function ($$v) {_vm.$set(_vm.state.awardOption, "point", _vm._n($$v))},expression:"state.awardOption.point"}}),_vm._v(" 點 "),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.v$.awardOption.point.$error}},[_vm._v("點數為必填")])],1),_c('b-form-radio',{attrs:{"value":"multiplier","disabled":_vm.isView || _vm.awardMultiplierDisabled}},[_vm._v(" 發熟客訂單商品金額 "),_c('b-form-input',{staticClass:"m-2",staticStyle:{"width":"7rem"},attrs:{"state":_vm.state.awardOption.type == 'multiplier' &&
                    (_vm.v$.awardOption.multiplier.$error ||
                      _vm.v$.awardOption.multiplier.$invalid)
                      ? false
                      : null,"disabled":_vm.state.awardOption.type != 'multiplier',"readonly":_vm.isView,"type":"number","min":"1","value":_vm.state.awardOption.multiplier + 1},on:{"input":function($event){_vm.state.awardOption.multiplier = parseInt($event) - 1}}}),_vm._v(" 倍 POYA 點數（含既有1倍） "),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.v$.awardOption.multiplier.$error}},[_vm._v("倍數為必填")])],1)],1)],1),_c('b-form-group',{attrs:{"label-cols":"12","label-size":"md","label":"單筆訂單贈獎上限"}},[_c('b-form-radio-group',{staticClass:"pt-3",attrs:{"id":"awardOption"},model:{value:(_vm.state.form.config.is_accm),callback:function ($$v) {_vm.$set(_vm.state.form.config, "is_accm", $$v)},expression:"state.form.config.is_accm"}},[_c('b-form-radio',{attrs:{"value":false,"disabled":_vm.isView}},[_vm._v("不可累贈")]),_c('b-form-radio',{attrs:{"value":true,"disabled":_vm.isView || !_vm.accmAble}},[_vm._v("可累贈")])],1),_c('hr'),_c('b-form-radio-group',{staticClass:"pt-3",attrs:{"stacked":""},model:{value:(_vm.state.orderLimitOption.type),callback:function ($$v) {_vm.$set(_vm.state.orderLimitOption, "type", $$v)},expression:"state.orderLimitOption.type"}},[_c('b-form-radio',{attrs:{"value":"order_unlimit","disabled":_vm.isView || _vm.limitOrderUnlimitDisabled}},[_vm._v(" 無上限 ")]),_c('b-form-radio',{attrs:{"value":"each_order_max_points","disabled":_vm.isView || _vm.limitOrderMaxPointDisabled}},[_vm._v(" 每筆訂單最多贈 "),_c('b-form-input',{staticClass:"m-2",staticStyle:{"width":"8rem"},attrs:{"state":(_vm.v$.orderLimitOption.value.$error ||
                      _vm.v$.orderLimitOption.value.$invalid) &&
                    _vm.state.orderLimitOption.type == 'each_order_max_points'
                      ? false
                      : null,"disabled":_vm.state.orderLimitOption.type != 'each_order_max_points',"readonly":_vm.isView,"type":"number","value":_vm.state.orderLimitOption.type == 'each_order_max_points'
                      ? _vm.state.orderLimitOption.value
                      : 0},on:{"input":function($event){_vm.state.orderLimitOption.value = parseInt($event)}}}),_vm._v(" 點 "),_c('b-form-invalid-feedback',{attrs:{"state":_vm.v$.orderLimitOption.value.$error &&
                    _vm.state.orderLimitOption.type == 'each_order_max_points'
                      ? false
                      : null}},[_vm._v("點數為必填，且為數字")])],1),_c('b-form-radio',{attrs:{"value":"each_order_max_awards","disabled":_vm.isView || _vm.limitOrderMaxAwardDisabled}},[_vm._v(" 每筆訂單最多贈 "),_c('b-form-input',{staticClass:"m-2",staticStyle:{"width":"8rem"},attrs:{"state":(_vm.v$.orderLimitOption.value.$error ||
                      _vm.v$.orderLimitOption.value.$invalid) &&
                    _vm.state.orderLimitOption.type == 'each_order_max_awards'
                      ? false
                      : null,"disabled":_vm.state.orderLimitOption.type != 'each_order_max_awards',"readonly":_vm.isView,"type":"number","value":_vm.state.orderLimitOption.type == 'each_order_max_awards'
                      ? _vm.state.orderLimitOption.value
                      : 0},on:{"input":function($event){_vm.state.orderLimitOption.value = parseInt($event)}}}),_vm._v(" 張券 "),_c('b-form-invalid-feedback',{attrs:{"state":_vm.v$.orderLimitOption.value.$error &&
                    _vm.state.orderLimitOption.type == 'each_order_max_awards'
                      ? false
                      : null}},[_vm._v("券數為必填，且為數字")])],1)],1)],1),_c('b-form-group',{attrs:{"label-cols":"12","label-size":"md","label":"每位會員贈獎上限"}},[_c('b-form-radio-group',{staticClass:"pt-3",attrs:{"stacked":""},model:{value:(_vm.state.memberLimitOption.type),callback:function ($$v) {_vm.$set(_vm.state.memberLimitOption, "type", $$v)},expression:"state.memberLimitOption.type"}},[_c('b-form-radio',{attrs:{"value":"member_unlimit","disabled":_vm.isView || _vm.limitMemberUnlimitDisabled}},[_vm._v(" 無上限 ")]),_c('b-form-radio',{attrs:{"value":"each_customer_max_points","disabled":_vm.isView || _vm.limitCustomerMaxPointDisabled}},[_vm._v(" 每位會員最多贈 "),_c('b-form-input',{staticClass:"m-2",staticStyle:{"width":"8rem"},attrs:{"state":(_vm.v$.memberLimitOption.value.$error ||
                      _vm.v$.memberLimitOption.value.$invalid) &&
                    _vm.state.memberLimitOption.type == 'each_customer_max_points'
                      ? false
                      : null,"disabled":_vm.state.memberLimitOption.type != 'each_customer_max_points',"readonly":_vm.isView,"type":"number","value":_vm.state.memberLimitOption.type == 'each_customer_max_points'
                      ? _vm.state.memberLimitOption.value
                      : 0},on:{"input":function($event){_vm.state.memberLimitOption.value = parseInt($event)}}}),_vm._v(" 點 "),_c('b-form-invalid-feedback',{attrs:{"state":_vm.v$.memberLimitOption.value.$error &&
                    _vm.state.memberLimitOption.type == 'each_customer_max_points'
                      ? false
                      : null}},[_vm._v("點數為必填，最多99999999點")])],1),_c('b-form-radio',{attrs:{"value":"each_customer_max_awards","disabled":_vm.isView || _vm.limitCustomerMaxAwardDisabled}},[_vm._v(" 每位會員最多贈 "),_c('b-form-input',{staticClass:"m-2",staticStyle:{"width":"8rem"},attrs:{"state":(_vm.v$.memberLimitOption.value.$error ||
                      _vm.v$.memberLimitOption.value.$invalid) &&
                    _vm.state.memberLimitOption.type == 'each_customer_max_awards'
                      ? false
                      : null,"disabled":_vm.state.memberLimitOption.type != 'each_customer_max_awards',"readonly":_vm.isView,"type":"number","value":_vm.state.memberLimitOption.type == 'each_customer_max_awards'
                      ? _vm.state.memberLimitOption.value
                      : 0},on:{"input":function($event){_vm.state.memberLimitOption.value = parseInt($event)}}}),_vm._v(" 張券 "),_c('b-form-invalid-feedback',{attrs:{"state":_vm.v$.orderLimitOption.value.$error &&
                    _vm.state.orderLimitOption.type == 'each_customer_max_awards'
                      ? false
                      : null}},[_vm._v("券數為必填，最多99999999張")])],1)],1)],1)],1)])]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"mr-4",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleCancel}},[_vm._v("取消")]),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.isSubmmiting,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[(!_vm.isView)?_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.isEdit ? "確認修改" : "確認新增")+" ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }