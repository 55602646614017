<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'AutoawardList',
              }"
              >自動派獎設定</b-breadcrumb-item
            >
            <b-breadcrumb-item
              :to="{
                name: 'AutoawardList',
              }"
              >自動派獎列表</b-breadcrumb-item
            >
            <b-breadcrumb-item active>
              {{ isEdit ? " 編輯" : "新增" }}自動派獎
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">
            {{ isEdit ? `編輯` : `新增` }}自動派獎
          </h4>
        </div>
      </div>

      <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <section class="row mb-8">
          <div class="col-12 col-xl-6">
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="標題"
              label-for="title"
            >
              <b-form-input
                id="title"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="state.form.title"
                :state="v$.form.title.$error ? false : null"
                :readonly="isView"
              ></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.title.$error"
                >此欄位為必填</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group>
              <b-form-row>
                <b-col class="col-md-6 col-sm-12">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="4"
                    label-size="sm"
                    label="活動起日"
                    label-for="start_at"
                  >
                    <datepicker
                      placeholder="Select Date"
                      :input-class="{
                        'form-control': true,
                        'is-invalid': v$.form.start_at_date.$error,
                      }"
                      :language="zh"
                      format="yyyy-MM-dd"
                      bootstrap-styling
                      v-model="state.form.start_at_date"
                      :disabled="isView || isStarted"
                      :disabled-dates="disabledDates"
                      style="max-width: 10rem"
                    >
                    </datepicker>
                    <b-form-invalid-feedback
                      :state="!v$.form.start_at_date.$error"
                      >活動起日為必填</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col class="col-md-6 col-sm-12">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="4"
                    label-size="sm"
                    label="活動迄日"
                    label-for="end_at"
                  >
                    <datepicker
                      placeholder="Select Date"
                      :input-class="{
                        'form-control': true,
                        'is-invalid': v$.form.end_at_date.$error,
                      }"
                      format="yyyy-MM-dd"
                      :language="zh"
                      bootstrap-styling
                      v-model="state.form.end_at_date"
                      :disabled="isView"
                      :disabled-dates="disabledEndDates"
                      style="max-width: 10rem"
                    >
                    </datepicker>
                    <b-form-invalid-feedback
                      :state="!v$.form.end_at_date.$error"
                      >活動迄日為必填</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form-group>

            <b-form-group label-cols="12" label-size="md" label="贈獎條件">
              <b-form-radio-group
                v-model="state.requirementOption.type"
                id="requirementOption"
                class="pt-3"
                stacked
              >
                <b-form-radio value="immediate" :disabled="isView"
                  >下單後立即符合資格</b-form-radio
                >
                <b-form-radio value="order_amount" :disabled="isView"
                  >下單滿
                  <b-form-input
                    v-model.number="state.requirementOption.amount"
                    :state="
                      v$.requirementOption.amount.$error ||
                      v$.requirementOption.amount.$invalid
                        ? false
                        : null
                    "
                    class="m-2"
                    :disabled="state.requirementOption.type != 'order_amount'"
                    :readonly="isView"
                    style="width: 6rem"
                    max="999999"
                    type="number"
                    maxLength="6"
                  ></b-form-input>
                  元符合資格
                  <b-form-invalid-feedback
                    :state="
                      !v$.requirementOption.amount.$error ||
                      !v$.requirementOption.amount.$invalid
                    "
                  >
                    只能輸入1-999999
                  </b-form-invalid-feedback>
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-size="md"
              label="通知 & 贈獎時間"
            >
              <b-form-radio-group
                v-model="state.notificationOption.after"
                class="pt-3"
              >
                <b-form-radio value="created_at" :disabled="isView"
                  >顧客下單後</b-form-radio
                >
                <b-form-radio value="pickup_at" :disabled="isView"
                  >顧客取貨後</b-form-radio
                >
              </b-form-radio-group>
              <hr />
              <b-form-radio-group
                v-model="state.notificationOption.after_day_type"
                class="pt-3"
                stacked
              >
                <b-form-radio
                  value="immediate"
                  :disabled="isView || notificationAfterDayImmediateDisabled"
                  >立即通知並贈獎</b-form-radio
                >
                <b-form-radio
                  value="pickup"
                  :disabled="isView || notificationAfterDayPickupDisabled"
                >
                  <b-form-input
                    :state="
                      state.notificationOption.after_day_type == 'pickup'
                        ? v$.notificationOption.after_days.$error ||
                          v$.notificationOption.after_days.$invalid
                          ? false
                          : null
                        : null
                    "
                    class="m-2"
                    :disabled="
                      state.notificationOption.after_day_type != 'pickup'
                    "
                    :readonly="isView"
                    style="width: 5rem"
                    type="number"
                    :value="
                      state.notificationOption.after_day_type == 'pickup'
                        ? state.notificationOption.after_days
                        : 0
                    "
                    @input="
                      state.notificationOption.after_days = parseInt($event)
                    "
                  ></b-form-input>
                  日發通知並贈獎
                </b-form-radio>
                <b-form-radio
                  value="pickup_award"
                  :disabled="isView || notificationAfterDayPickupAwardDisabled"
                >
                  <b-form-input
                    :state="
                      state.notificationOption.after_day_type == 'pickup_award'
                        ? v$.notificationOption.after_days.$error ||
                          v$.notificationOption.after_days.$invalid
                          ? false
                          : null
                        : null
                    "
                    class="m-2"
                    :disabled="
                      state.notificationOption.after_day_type != 'pickup_award'
                    "
                    :readonly="isView"
                    :value="
                      state.notificationOption.after_day_type == 'pickup_award'
                        ? state.notificationOption.after_days
                        : 0
                    "
                    @input="
                      (e) => {
                        state.awardOption.after_days =
                          state.awardOption.after_days -
                          state.notificationOption.after_days +
                          parseInt(e);
                        state.notificationOption.after_days = parseInt(e);
                      }
                    "
                    style="width: 5rem"
                    type="number"
                    min="1"
                  ></b-form-input>
                  日發通知，通知後
                  <b-form-input
                    :state="
                      state.notificationOption.after_day_type == 'pickup_award'
                        ? v$.awardOption.after_days.$error ||
                          v$.awardOption.after_days.$invalid
                          ? false
                          : null
                        : null
                    "
                    class="m-2"
                    :disabled="
                      state.notificationOption.after_day_type != 'pickup_award'
                    "
                    :readonly="isView"
                    :value="
                      state.notificationOption.after_day_type == 'pickup_award'
                        ? state.awardOption.after_days -
                          state.notificationOption.after_days
                        : 0
                    "
                    @input="
                      state.awardOption.after_days =
                        parseInt($event) + state.notificationOption.after_days
                    "
                    style="width: 5rem"
                    type="number"
                    min="1"
                  ></b-form-input>
                  日贈獎
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group label-cols="12" label-size="md" label="贈獎內容">
              <b-form-radio-group
                v-model="state.awardOption.type"
                class="pt-3"
                stacked
              >
                <b-form-radio
                  value="url"
                  :disabled="isView || awardUrlDisabled"
                >
                  發漸強導流連結
                  <b-form-input
                    v-model="state.notificationOption.url"
                    :state="
                      v$.notificationOption.url.$error ||
                      v$.notificationOption.url.$invalid
                        ? false
                        : null
                    "
                    class="m-2"
                    :disabled="state.awardOption.type != 'url'"
                    :readonly="isView"
                    style="width: 300px"
                    placeholder="請輸入網址"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="
                      !v$.notificationOption.url.$error ||
                      !v$.notificationOption.url.$invalid
                    "
                    >導流連結為必填，且為網址格式</b-form-invalid-feedback
                  >
                </b-form-radio>
                <b-form-radio
                  value="cresclab_template"
                  :disabled="isView || awardTemplateDisabled"
                >
                  發漸強後台訊息範本 ID
                  <b-form-input
                    v-model="state.awardOption.cresclab_template_id"
                    :state="
                      state.awardOption.type == 'cresclab_template' &&
                      (v$.awardOption.cresclab_template_id.$error ||
                        v$.awardOption.cresclab_template_id.$invalid)
                        ? false
                        : null
                    "
                    class="m-2"
                    :disabled="state.awardOption.type != 'cresclab_template'"
                    :readonly="isView"
                    style="width: 7rem"
                    type="number"
                  ></b-form-input>
                  訊息
                  <b-form-invalid-feedback
                    :state="!v$.awardOption.cresclab_template_id.$error"
                    >ID 為必填</b-form-invalid-feedback
                  >
                </b-form-radio>
                <b-form-radio
                  value="point"
                  :disabled="isView || awardPointDisabled"
                >
                  發 POYA 點數
                  <b-form-input
                    v-model.number="state.awardOption.point"
                    :state="
                      state.awardOption.type == 'point' &&
                      (v$.awardOption.point.$error ||
                        v$.awardOption.point.$invalid)
                        ? false
                        : null
                    "
                    class="m-2"
                    :disabled="state.awardOption.type != 'point'"
                    :readonly="isView"
                    style="width: 7rem"
                    type="number"
                  ></b-form-input>
                  點
                  <b-form-invalid-feedback :state="!v$.awardOption.point.$error"
                    >點數為必填</b-form-invalid-feedback
                  >
                </b-form-radio>
                <b-form-radio
                  value="multiplier"
                  :disabled="isView || awardMultiplierDisabled"
                >
                  發熟客訂單商品金額
                  <b-form-input
                    :state="
                      state.awardOption.type == 'multiplier' &&
                      (v$.awardOption.multiplier.$error ||
                        v$.awardOption.multiplier.$invalid)
                        ? false
                        : null
                    "
                    class="m-2"
                    :disabled="state.awardOption.type != 'multiplier'"
                    :readonly="isView"
                    style="width: 7rem"
                    type="number"
                    min="1"
                    :value="state.awardOption.multiplier + 1"
                    @input="state.awardOption.multiplier = parseInt($event) - 1"
                  ></b-form-input>
                  倍 POYA 點數（含既有1倍）
                  <b-form-invalid-feedback
                    :state="!v$.awardOption.multiplier.$error"
                    >倍數為必填</b-form-invalid-feedback
                  >
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-size="md"
              label="單筆訂單贈獎上限"
            >
              <b-form-radio-group
                v-model="state.form.config.is_accm"
                id="awardOption"
                class="pt-3"
              >
                <b-form-radio :value="false" :disabled="isView"
                  >不可累贈</b-form-radio
                >
                <b-form-radio :value="true" :disabled="isView || !accmAble"
                  >可累贈</b-form-radio
                >
              </b-form-radio-group>
              <hr />
              <b-form-radio-group
                v-model="state.orderLimitOption.type"
                class="pt-3"
                stacked
              >
                <b-form-radio
                  value="order_unlimit"
                  :disabled="isView || limitOrderUnlimitDisabled"
                >
                  無上限
                </b-form-radio>
                <b-form-radio
                  value="each_order_max_points"
                  :disabled="isView || limitOrderMaxPointDisabled"
                >
                  每筆訂單最多贈
                  <b-form-input
                    :state="
                      (v$.orderLimitOption.value.$error ||
                        v$.orderLimitOption.value.$invalid) &&
                      state.orderLimitOption.type == 'each_order_max_points'
                        ? false
                        : null
                    "
                    class="m-2"
                    :disabled="
                      state.orderLimitOption.type != 'each_order_max_points'
                    "
                    :readonly="isView"
                    type="number"
                    style="width: 8rem"
                    :value="
                      state.orderLimitOption.type == 'each_order_max_points'
                        ? state.orderLimitOption.value
                        : 0
                    "
                    @input="state.orderLimitOption.value = parseInt($event)"
                  ></b-form-input>
                  點
                  <b-form-invalid-feedback
                    :state="
                      v$.orderLimitOption.value.$error &&
                      state.orderLimitOption.type == 'each_order_max_points'
                        ? false
                        : null
                    "
                    >點數為必填，且為數字</b-form-invalid-feedback
                  >
                </b-form-radio>
                <b-form-radio
                  value="each_order_max_awards"
                  :disabled="isView || limitOrderMaxAwardDisabled"
                >
                  每筆訂單最多贈
                  <b-form-input
                    :state="
                      (v$.orderLimitOption.value.$error ||
                        v$.orderLimitOption.value.$invalid) &&
                      state.orderLimitOption.type == 'each_order_max_awards'
                        ? false
                        : null
                    "
                    class="m-2"
                    :disabled="
                      state.orderLimitOption.type != 'each_order_max_awards'
                    "
                    :readonly="isView"
                    type="number"
                    style="width: 8rem"
                    :value="
                      state.orderLimitOption.type == 'each_order_max_awards'
                        ? state.orderLimitOption.value
                        : 0
                    "
                    @input="state.orderLimitOption.value = parseInt($event)"
                  ></b-form-input>
                  張券
                  <b-form-invalid-feedback
                    :state="
                      v$.orderLimitOption.value.$error &&
                      state.orderLimitOption.type == 'each_order_max_awards'
                        ? false
                        : null
                    "
                    >券數為必填，且為數字</b-form-invalid-feedback
                  >
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-size="md"
              label="每位會員贈獎上限"
            >
              <b-form-radio-group
                v-model="state.memberLimitOption.type"
                class="pt-3"
                stacked
              >
                <b-form-radio
                  value="member_unlimit"
                  :disabled="isView || limitMemberUnlimitDisabled"
                >
                  無上限
                </b-form-radio>
                <b-form-radio
                  value="each_customer_max_points"
                  :disabled="isView || limitCustomerMaxPointDisabled"
                >
                  每位會員最多贈
                  <b-form-input
                    :state="
                      (v$.memberLimitOption.value.$error ||
                        v$.memberLimitOption.value.$invalid) &&
                      state.memberLimitOption.type == 'each_customer_max_points'
                        ? false
                        : null
                    "
                    class="m-2"
                    :disabled="
                      state.memberLimitOption.type != 'each_customer_max_points'
                    "
                    :readonly="isView"
                    type="number"
                    style="width: 8rem"
                    :value="
                      state.memberLimitOption.type == 'each_customer_max_points'
                        ? state.memberLimitOption.value
                        : 0
                    "
                    @input="state.memberLimitOption.value = parseInt($event)"
                  ></b-form-input>
                  點
                  <b-form-invalid-feedback
                    :state="
                      v$.memberLimitOption.value.$error &&
                      state.memberLimitOption.type == 'each_customer_max_points'
                        ? false
                        : null
                    "
                    >點數為必填，最多99999999點</b-form-invalid-feedback
                  >
                </b-form-radio>
                <b-form-radio
                  value="each_customer_max_awards"
                  :disabled="isView || limitCustomerMaxAwardDisabled"
                >
                  每位會員最多贈
                  <b-form-input
                    :state="
                      (v$.memberLimitOption.value.$error ||
                        v$.memberLimitOption.value.$invalid) &&
                      state.memberLimitOption.type == 'each_customer_max_awards'
                        ? false
                        : null
                    "
                    class="m-2"
                    :disabled="
                      state.memberLimitOption.type != 'each_customer_max_awards'
                    "
                    :readonly="isView"
                    type="number"
                    style="width: 8rem"
                    :value="
                      state.memberLimitOption.type == 'each_customer_max_awards'
                        ? state.memberLimitOption.value
                        : 0
                    "
                    @input="state.memberLimitOption.value = parseInt($event)"
                  ></b-form-input>
                  張券
                  <b-form-invalid-feedback
                    :state="
                      v$.orderLimitOption.value.$error &&
                      state.orderLimitOption.type == 'each_customer_max_awards'
                        ? false
                        : null
                    "
                    >券數為必填，最多99999999張</b-form-invalid-feedback
                  >
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </section>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel"
          >取消</b-button
        >
        <b-overlay
          :show="isSubmmiting"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button variant="success" @click="handleSubmit" v-if="!isView">
            {{ isEdit ? "確認修改" : "確認新增" }}
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>
<style scoped>
::v-deep .custom-control-label {
  justify-content: start;
  flex-wrap: wrap;
}
::v-deep .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
}
::v-deep .custom-control-label::before {
  top: 50%;
  transform: translateY(-50%);
}

::v-deep .col-form-label.col-form-label-md {
  background: var(--sidebar-bg-color);
  color: #fff;
}
</style>

<script>
import { reactive } from "@vue/composition-api";
import { mapState } from "vuex";
import { zh } from "vuejs-datepicker/dist/locale";
import subDays from "date-fns/subDays";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import useVuelidate from "@vuelidate/core";
import {
  required,
  requiredIf,
  numeric,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import autoawardApi from "../../../apis/autoaward";
import orgNameMixin from "@/mixins/organizationName";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import _ from "lodash";

// const minValueIfNotUnlimit = (state, limitType) => {
//   console.log(state, limitType);

//   return !!limitType && !["order_unlimit", "member_unlimit"].includes(limitType)
//     ? 1
//     : null;
// };

export default {
  components: { Datepicker },
  mixins: [orgNameMixin],
  setup() {
    const state = reactive({
      form: {
        title: "",
        start_at: null,
        end_at: null,
        start_at_date: moment().format("YYYY-MM-DD 00:00:00"),
        end_at_date: moment().format("YYYY-MM-DD 23:59:59"),
        start_at_time: "00:00",
        end_at_time: "23:59:59",
        //plus_day: 0,
        config: {
          version: 3,
          is_accm: false,
          //limit_type: "member",
          requirements: [],
          notifies: [],
          awards: [],
          limits: [],
        },
      },
      awardOption: {
        type: "url",
        point: 1,
        after_days: 0,
        after: 0,
        multiplier: 0,
        cresclab_template_id: null,
      },
      notificationOption: {
        after: "created_at",
        after_days: 1,
        after_day_type: "immediate",
        //plus_day: 0,
        url: null,
        template_code: null,
      },
      orderLimitOption: {
        type: "order_unlimit",
        value: 0,
      },
      memberLimitOption: {
        type: "member_unlimit",
        value: 0,
      },
      requirementOption: {
        amount: 1,
        type: "immediate",
      },
    });
    //const limitType = ref(state.orderLimitOption.type);

    const rules = {
      form: {
        title: { required },
        start_at_date: { required },
        start_at_time: { required },
        end_at_date: { required },
        end_at_time: { required },
      },
      notificationOption: {
        type: {},
        after_days: {
          numeric,
          required: requiredIf(function () {
            return this.state.notificationOption.after == "pickup_at";
          }),
          range: (value) => {
            if (state.notificationOption.after_day_type != "immediate") {
              try {
                return value > 0 && value <= 99;
              } catch (e) {
                return false;
              }
            }
            return true;
          },
        },
        // plus_day: {
        //   numeric,
        //   required: requiredIf(function () {
        //     return this.state.notificationOption.after == "pickup_at";
        //   }),
        //   daysRange,
        // },
        url: {
          required: requiredIf(function () {
            return this.state.awardOption.type == "url";
          }),
        },
        // template_code: {
        //   required: requiredIf(function () {
        //     return this.state.awardOption.type == "cresclab_template";
        //   }),
        //   range: (value) => {
        //     if (
        //       state.awardOption.type =="cresclab_template"
        //     ) {
        //       return value >= 1 && value <= 99999999;
        //     } else {
        //       return true;
        //     }
        //   }
        // },
      },
      orderLimitOption: {
        value: {
          numeric,
          required: requiredIf(function () {
            return (
              !!state.orderLimitOption.type &&
              !["order_unlimit"].includes(state.orderLimitOption.type)
            );
          }),
          range: (value) => {
            if (
              !!state.orderLimitOption.type &&
              !["order_unlimit"].includes(state.orderLimitOption.type)
            ) {
              return value >= 1 && value <= 99999999;
            } else {
              return true;
            }
          },
          // maxValue: (value) => {
          //   if (
          //     !!state.orderLimitOption.type &&
          //     !["order_unlimit"].includes(state.orderLimitOption.type)
          //   ) {
          //     return value <= 99999999;
          //   } else {
          //     return true;
          //   }
          // },
        },
      },
      memberLimitOption: {
        value: {
          numeric,
          required: requiredIf(function () {
            return (
              !!state.memberLimitOption.type &&
              !["member_unlimit"].includes(state.memberLimitOption.type)
            );
          }),
          range: (value) => {
            if (
              !!state.memberLimitOption.type &&
              !["member_unlimit"].includes(state.memberLimitOption.type)
            ) {
              return value >= 1 && value <= 99999999;
            } else {
              return true;
            }
          },
          // minValue: (value) => {
          //   if (
          //     !!state.memberLimitOption.type &&
          //     !["member_unlimit"].includes(state.memberLimitOption.type)
          //   ) {
          //     return value >= 1;
          //   } else {
          //     return true;
          //   }
          // },
          // maxValue: (value) => {
          //   if (
          //     !!state.memberLimitOption.type &&
          //     !["member_unlimit"].includes(state.memberLimitOption.type)
          //   ) {
          //     return value <= 99999999;
          //   } else {
          //     return true;
          //   }
          // },
        },
      },
      requirementOption: {
        amount: {
          numeric,
          required: requiredIf(function () {
            return this.state.requirementOption.type == "order_amount";
          }),
          minValue: minValue(1),
          maxValue: maxValue(999999),
        },
      },
      awardOption: {
        type: {},
        after: {},
        after_days: {
          numeric,
          required: requiredIf(function () {
            return this.state.notificationOption.after == "pickup_at";
          }),
          range: (value) => {
            if (state.notificationOption.after_day_type == "pickup_award") {
              return (
                value - state.notificationOption.after_days > 0 &&
                value - state.notificationOption.after_days <= 99999999
              );
            } else {
              return true;
            }
          },
        },
        point: {
          numeric,
          required: requiredIf(function () {
            return this.state.awardOption.type == "point";
          }),
          minValue: minValue(1),
          maxValue: maxValue(99999999),
        },
        multiplier: {
          numeric,
          required: requiredIf(function () {
            return state.awardOption.type == "multiplier";
          }),
          minValue: minValue(0),
          maxValue: maxValue(99999999),
        },
        cresclab_template_id: {
          required: requiredIf(function () {
            return this.state.awardOption.type == "cresclab_template";
          }),
          range: (value) => {
            if (state.awardOption.type == "cresclab_template") {
              return value >= 1 && value <= 99999999;
            } else {
              return true;
            }
          },
        },
      },
    };

    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  // 這邊需要參考各種狀況切換是否 disable
  // 參考 https://github.com/Waltily-Inc/waltily/issues/376
  watch: {
    // "state.requirementOption.type": function (value) {
    //   this.state.form.config.is_accm = value == "order_amount"; // 滿額才可以累積
    //   switch (value) {
    //     case "immediate":
    //       this.accmTrueDisabled = true;
    //       this.accmFalseDisabled = false;
    //       break;
    //     case "order_amount":
    //       this.accmTrueDisabled = false;
    //       this.accmFalseDisabled = false;
    //       break;
    //   }
    // },
    accmAble: function (value) {
      if (!value && this.state.form.config.is_accm) {
        this.state.form.config.is_accm = false;
      }
    },
    "state.notificationOption.after": function (value) {
      this.toggleAward(value, this.state.notificationOption.after_day_type);
    },
    "state.notificationOption.after_day_type": function (value) {
      this.toggleAward(this.state.notificationOption.after, value);
    },
    "state.awardOption.type": function (value) {
      switch (value) {
        case "url":
          this.limitOrderUnlimitDisabled = false;
          this.limitOrderMaxPointDisabled = true;
          this.limitOrderMaxAwardDisabled = true;

          this.limitMemberUnlimitDisabled = false;
          this.limitCustomerMaxPointDisabled = true;
          this.limitCustomerMaxAwardDisabled = true;

          //this.state.form.config.is_accm = false;

          this.accmTrueDisabled = true;
          // this.state.requirementOption.type != "order_amount"),
          this.accmFalseDisabled = false;

          this.state.orderLimitOption.type = "order_unlimit";

          this.state.memberLimitOption.type = "member_unlimit";

          break;
        case "point":
          this.limitOrderUnlimitDisabled = false;
          this.limitOrderMaxPointDisabled = false;
          this.limitOrderMaxAwardDisabled = true;
          this.limitMemberUnlimitDisabled = false;
          this.limitCustomerMaxPointDisabled = false;
          this.limitCustomerMaxAwardDisabled = true;
          this.accmTrueDisabled =
            this.state.requirementOption.type != "order_amount";
          this.accmFalseDisabled = false;

          if (
            !["each_order_max_points", "order_unlimit"].includes(
              this.state.orderLimitOption.type
            )
          )
            this.state.orderLimitOption.type = "order_unlimit";
          if (
            !["each_customer_max_points", "member_unlimit"].includes(
              this.state.memberLimitOption.type
            )
          )
            this.state.state.memberLimitOption.type = "member_unlimit";

          break;
        case "multiplier":
          this.limitOrderUnlimitDisabled = false;
          this.limitOrderMaxPointDisabled = false;
          this.limitOrderMaxAwardDisabled = true;
          this.limitMemberUnlimitDisabled = false;
          this.limitCustomerMaxPointDisabled = false;
          this.limitCustomerMaxAwardDisabled = true;

          //this.state.form.config.is_accm = false;
          (this.accmTrueDisabled = true),
            //this.state.requirementOption.type != "order_amount"),
            (this.accmFalseDisabled = false);

          if (
            !["each_order_max_points", "order_unlimit"].includes(
              this.state.orderLimitOption.type
            )
          )
            this.state.orderLimitOption.type = "order_unlimit";
          if (
            !["each_customer_max_points", "member_unlimit"].includes(
              this.state.memberLimitOption.type
            )
          )
            this.state.memberLimitOption.type = "member_unlimit";

          break;
        case "cresclab_template":
          this.limitOrderUnlimitDisabled = false;
          this.limitOrderMaxPointDisabled = true;
          this.limitOrderMaxAwardDisabled = !this.state.form.config.is_accm; // 這個還需要判斷
          this.limitMemberUnlimitDisabled = false;
          this.limitCustomerMaxPointDisabled = true;
          this.limitCustomerMaxAwardDisabled = false;

          this.accmTrueDisabled =
            this.state.requirementOption.type != "order_amount";
          this.accmFalseDisabled = false;

          if (
            !["each_order_max_awards", "order_unlimit"].includes(
              this.state.orderLimitOption.type
            )
          )
            this.state.orderLimitOption.type = "order_unlimit";
          if (
            !["each_customer_max_awards", "member_unlimit"].includes(
              this.state.memberLimitOption.type
            )
          ) {
            this.state.memberLimitOption.type = "member_unlimit";
          }

          break;
      }
    },
    "state.form.config.is_accm": function (value) {
      this.limitOrderMaxAwardDisabled = !(
        value && this.state.awardOption.type == "template"
      );

      //選擇可累贈才能選擇單筆訂單最多贈？張券
      if (
        !value &&
        this.state.orderLimitOption.type == "each_order_max_awards"
      ) {
        this.state.orderLimitOption.type = "order_unlimit";
        this.state.orderLimitOption.value = 0;
      }
    },
    "state.orderLimitOption.type": function (value) {
      if (value == "order_unlimit") this.state.orderLimitOption.value = 0;
    },
    "state.memberLimitOption.type": function (value) {
      if (value == "member_unlimit") this.state.memberLimitOption.value = 0;
    },
  },
  data() {
    return {
      isStarted: false,
      disabledDates: { to: subDays(new Date(), 1) },
      zh,
      isLoading: false,
      isSubmmiting: false,
      form: {
        title: "",
        start_at: undefined,
        end_at: undefined,
        config: [],
      },
      notificationAfterDayImmediateDisabled: false,
      notificationAfterDayPickupDisabled: false,
      notificationAfterDayPickupAwardDisabled: false,

      awardUrlDisabled: false,
      awardTemplateDisabled: true,
      awardPointDisabled: true,
      awardMultiplierDisabled: true,

      limitOrderUnlimitDisabled: false,
      limitMemberUnlimitDisabled: false,
      limitOrderMaxPointDisabled: true,
      limitCustomerMaxPointDisabled: true,
      limitOrderMaxAwardDisabled: true,
      limitCustomerMaxAwardDisabled: true,

      accmTrueDisabled: true,
      accmFalseDisabled: false,
    };
  },
  validations() {
    return {
      form: {
        title: { required },
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    disabledEndDates() {
      // 活動開始後只能選今日以後，不然就只能選起日之後
      return isBefore(this.state.form.start_at_date, new Date())
        ? { to: subDays(new Date(), 1) }
        : { to: new Date(this.state.form.start_at_date) };
    },
    accmAble() {
      return (
        this.state.requirementOption.type == "order_amount" &&
        !["url", "multiplier"].includes(this.state.awardOption.type)
      );
    },
    autoawardId() {
      return this.$route.params.autoawardId;
    },
    merchantId() {
      return this.$route.query.merchantId;
    },
    isEdit() {
      return this.$route.name === "AutoawardEditV3";
    },
    isView() {
      return this.$route.name === "AutoawardViewV3";
    },
  },
  mounted() {
    if (
      !this.checkPermission([consts.POYA_AUTOAWARD_MODIFY]) ||
      !this.checkPermission([consts.POYA_AUTOAWARD_CREATE])
    ) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.$router.push({ name: "AutoawardList" });
        });
    } else if (!this.isEdit && !this.isView && !this.merchantId) {
      this.$swal
        .fire({
          type: "error",
          text: "未指定通路",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.$router.push({ name: "AutoawardList" });
        });
    } else if (this.autoawardId) {
      this.fetchAutoaward();
    }
  },
  methods: {
    maxLength(length) {
      return (v) => {
        return String(v).substring(0, length);
      };
    },
    isBeforeNow(date) {
      return isBefore(new Date(date), new Date());
    },
    isAfterNow(date) {
      return isAfter(new Date(date), new Date());
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    unwrapConfig(config) {
      return {
        notifies: config.notifies.pop() || {},
        requirements: config.requirements.pop() || {},
        awards: config.awards.pop() || {},
        //limits: config.limits.pop() || [],

        limits: config.limits,
      };
    },
    toggleAward(after, after_day_type) {
      if (this.isEdit) {
        this.awardUrlDisabled = false;
        this.awardTemplateDisabled = false;
        this.awardPointDisabled = false;
        this.awardMultiplierDisabled = false;
      }
      this.awardUrlDisabled = true;
      this.awardTemplateDisabled = true;
      this.awardPointDisabled = true;
      this.awardMultiplierDisabled = true;

      switch (after) {
        case "created_at":
          switch (after_day_type) {
            case "immediate":
            case "pickup":
              this.awardUrlDisabled = false;
              this.state.awardOption.type = "url";
              //this.state.form.config.is_accm = false;
              this.state.awardOption.after_days = 0;
              break;
            case "pickup_award":
              this.awardTemplateDisabled = false;
              this.state.awardOption.type = "template";
              if (
                this.state.awardOption.after_days -
                  this.state.notificationOption.after_days <=
                1
              ) {
                this.state.awardOption.after_days =
                  this.state.notificationOption.after_days + 1;
              }

            // if(this.state.requirementOption.type == "order_amount")
            //   this.state.form.config.is_accm = true;
          }
          break;
        case "pickup_at":
          this.awardUrlDisabled = false;
          this.awardPointDisabled = false;
          this.awardMultiplierDisabled = false;
          if (this.state.awardOption.type === "template") {
            this.state.awardOption.type = "url";
          }
          //this.state.form.config.is_accm = false;

          if (after_day_type == "pickup_award") {
            this.awardUrlDisabled = true;
            this.awardTemplateDisabled = false;

            if (
              this.state.awardOption.after_days -
                this.state.notificationOption.after_days <=
              1
            ) {
              this.state.awardOption.after_days =
                this.state.notificationOption.after_days + 1;
            }

            if (this.state.awardOption.type == "url")
              this.state.awardOption.type = "template";
          }

          if (["pickup", "immediate"].includes(after_day_type))
            this.state.awardOption.after_days = 0;

          break;
      }
    },
    async prepareConfig() {
      // 這邊 Paddy 覺得以後會有多選項，所以很多都是用 array 儲存
      let { notifies, requirements, awards, limits } = this.unwrapConfig(
        this.state.form.config
      );

      requirements =
        this.state.requirementOption.type != "order_amount"
          ? {}
          : {
              type: "order_amount",
              amount: this.state.requirementOption.amount,
            };

      notifies = {
        after: this.state.notificationOption.after,
        after_days:
          this.state.notificationOption.after_day_type == "immediate"
            ? 0
            : parseInt(this.state.notificationOption.after_days || 0),
        type:
          //this.state.awardOption.type == "template" ? "cresclab" : "waltily",
          "waltily",
      };

      awards = {
        type: this.state.awardOption.type,
        after: this.state.notificationOption.after,
        after_days: this.state.awardOption.after_days,       
        // this.state.notificationOption.after_day_type != "pickup_award"
        //   ? 0
        //   : Math.max(
        //       (+this.state.notificationOption.plus_day || 0) +
        //         (+this.state.notificationOption.after_days || 0),
        //       0
        //     ),
      };

      // if (["url", "template"].includes(this.state.awardOption.type)) {
      //   awards = {};
      // }

      switch (this.state.awardOption.type) {
        case "url":
          notifies["url"] = this.state.notificationOption.url;
          notifies["template_code"] = "groupbuying_award_link";
          break;
        case "cresclab_template":
          //notifies["template_code"] =
          //this.state.notificationOption.template_code;
          notifies["template_code"] = "groupbuying_award_cresclab_template";
          awards["cresclab_template_id"] = parseInt(this.state.awardOption.cresclab_template_id);
          break;
        case "point":
          awards["point"] = parseInt(this.state.awardOption.point);
          notifies["template_code"] = "groupbuying_award_point";
          break;
        case "multiplier":
          awards["multiplier"] = parseInt(this.state.awardOption.multiplier);
          notifies["template_code"] = "groupbuying_award_multiplier";
          break;
      }

      // limits = !["order_unlimit", "member_unlimit"].includes(
      //   this.state.orderLimitOption.type
      // )
      //   ? {
      //       ...this.state.orderLimitOption,
      //     }
      //   : {};
      limits = [];

      if (this.state.orderLimitOption.type)
        limits.push(this.state.orderLimitOption);
      limits.push(this.state.memberLimitOption);
      // if (
      //   this.state.orderLimitOption.type != "order_unlimit" &&
      //   this.state.orderLimitOption.type != ""
      // )

      // if (
      //   this.state.memberLimitOption.type != "member_unlimit" &&
      //   this.state.memberLimitOption.type != ""
      // )

      console.log(limits);

      // if (this.state.orderLimitOption.type.includes("member")) {
      //   this.state.form.config.limit_type = "member";
      // } else if (this.state.orderLimitOption.type.includes("order")) {
      //   this.state.form.config.limit_type = "order";
      // }

      for (const [key, value] of Object.entries({
        notifies,
        requirements,
        awards,
        //limits,
      })) {
        if (!_.isEmpty(value)) {
          this.state.form.config[key].push(value);
        }
      }

      this.state.form.config["limits"] = limits;
    },
    restoreConfigOption(config) {
      let { notifies, requirements, awards, limits } =
        this.unwrapConfig(config);

      console.log(config);
      console.log(notifies, requirements, awards, limits);

      // 由於 limit 無限是會清空的，需要輔助一個 limit type 來判斷
      // switch (true) {
      //   case _.isEmpty(limits) && limits.type == "member":
      //     limits.type = "member_unlimit";
      //     break;
      //   case _.isEmpty(limits) && limits.type == "order":
      //     limits.type = "order_unlimit";
      //     break;
      // }

      this.state.awardOption = {
        ...awards,
      };

      if (this.state.awardOption.multiplier == undefined) {
        this.state.awardOption.multiplier = 0;
      }

      this.state.notificationOption = {
        ...notifies,
      };

      this.state.orderLimitOption = limits.find((x) =>
        [
          "each_order_max_points",
          "each_order_max_awards",
          "order_unlimit",
        ].includes(x.type)
      ) ?? { type: "", value: 0 };

      this.state.memberLimitOption = limits.find((x) =>
        [
          "each_customer_max_points",
          "each_customer_max_awards",
          "member_unlimit",
        ].includes(x.type)
      ) ?? { type: "", value: 0 };

      this.state.requirementOption = _.isEmpty(requirements)
        ? { type: "immediate" }
        : {
            ...requirements,
          };

      // 這裡因為前端混用了 award & notifies，所以只能先這樣拆開。否則下面這段這樣寫是不好的
      // if (["cresclab", "waltily"].includes(notifies.type)) {
      //   this.state.awardOption.type = "url";
      // }

      let after_day_type = "immediate";

      switch (true) {
        case !!this.state.awardOption.after_days &&
          !!this.state.notificationOption.after_days &&
          this.state.awardOption.after_days > 0 &&
          this.state.notificationOption.after_days > 0:
          after_day_type = "pickup_award";
          break;
        case !!this.state.notificationOption.after_days &&
          this.state.notificationOption.after_days > 0:
          after_day_type = "pickup";
          break;
        default:
          after_day_type = "immediate";
          break;
      }

      console.log(after_day_type);

      this.state.notificationOption = {
        ...notifies,
        after_day_type,
      };

      // this.state.plus_day = Math.max(
      //   (this.state.awardOption.after_days || 0) -
      //     (this.state.notificationOption.after_days || 0),
      //   0
      // );
      this.state.form.config.is_accm = config.is_accm;
    },
    deleteConfig(i) {
      this.$delete(this.state.form.config, i);
    },
    clearAwardOption() {
      this.state.awardOption = {
        type: null,
        day: null,
        template_id: null,
        point: null,
        multiplier: null,
      };
    },
    async fetchAutoaward() {
      try {
        this.isLoading = true;
        const { data } = await autoawardApi.getAutoaward(this.autoawardId);

        const { title, config, start_at, end_at } = data.data;

        if (this.isEdit || this.isView) {
          this.form = {
            ...this.form,
          };
          this.state.form = {
            title,
            config,
            start_at_date: moment(start_at).isValid()
              ? moment(start_at).format("YYYY-MM-DD")
              : undefined,
            start_at_time: moment(start_at).isValid()
              ? moment(start_at).format("HH:mm")
              : "00:00",
            end_at_date: moment(end_at).isValid()
              ? moment(end_at).format("YYYY-MM-DD")
              : undefined,
            end_at_time: moment(end_at).isValid()
              ? moment(end_at).format("HH:mm")
              : "00:00",
          };
          this.isStarted =
            this.isEdit && isBefore(new Date(start_at), new Date());

          this.restoreConfigOption(config);
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    async handleSubmit() {
      this.prepareConfig();

      console.log(this.state.form);
      this.state.form.start_at = moment(this.state.form.start_at_date).format(
        "YYYY-MM-DD 00:00:00"
      );
      this.state.form.end_at = moment(this.state.form.end_at_date).format(
        "YYYY-MM-DD 23:59:59"
      );
      const result = await this.v$.$validate();
      console.log(result);
      if (!result) return;

      if (this.isEdit) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }
    },
    async handleUpdate() {
      try {
        this.isSubmmiting = true;
        await autoawardApi.updateAutoaward(
          this.autoawardId,
          this.omitNullDate({
            ...this.form,
            ...this.state.form,
          })
        );
        this.showSuccessPopup();
      } catch (error) {
        console.error(error);
        const { status, data } = error.response;
        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      }
      this.isSubmmiting = false;
    },
    async handleCreate() {
      try {
        this.isSubmmiting = true;
        await autoawardApi.createAutoaward(
          this.merchantId,
          this.omitNullDate({
            ...this.form,
            ...this.state.form,
          })
        );
        this.showSuccessPopup();
      } catch (error) {
        const { status, data } = error.response;
        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      }
      this.isSubmmiting = false;
    },
    handleCancel() {
      this.$router.push({ name: "AutoawardList" });
    },
    showSuccessPopup() {
      this.$swal
        .fire({
          type: "success",
          text: this.isEdit ? "修改成功" : "新增成功",
        })
        .then(() => {
          this.$router.push({ name: "AutoawardList" });
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    omitNullDate(form) {
      const result = { ...form };

      if (!result.start_at) {
        delete result.start_at;
      }
      if (!result.end_at) {
        delete result.end_at;
      }

      return result;
    },
  },
};
</script>
